import React from 'react';
import SEO from '../components/seo';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const Realisations = () => (
  <>
    <SEO title="Réalisations" />
    <Realistyle>
      <StaticQuery
        query={REAL_LISTING}
        render={({ allMarkdownRemark }) =>
          allMarkdownRemark.edges.map(({ node }) => {
            if (!node.frontmatter.hidden) {
              return (
                <li key={node.fields.slug}>
                  <Link to={node.fields.slug}>
                    <StyledImg
                      fluid={
                        node.frontmatter.thumbnailvertical.childImageSharp.fluid
                      }
                    />
                    <h3 style={{ fontWeight: '200' }}>
                      <span>{node.frontmatter.title}</span>
                    </h3>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={node.frontmatter.title}>
                  <BlurriedImg>
                    <img
                      style={{ transform: randomPos() }}
                      src="/img/ABBESSES-01-salon-1.jpg"
                      alt="Projet en cours de réalisation"
                    />
                  </BlurriedImg>
                  <h3 style={{ fontWeight: '200' }}>
                    <span>- A VENIR -</span>
                  </h3>
                </li>
              );
            }
          })
        }
      />
    </Realistyle>
  </>
);

const REAL_LISTING = graphql`
  query RealisationsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { hidden: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            hidden
            thumbnailvertical {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
          }
        }
      }
    }
  }
`;
const Realistyle = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
  li {
    height: 55vh;
    width: 19%;
    position: relative;
    margin: 0.1%;
    text-align: center;
    @media screen and (max-width: 920px) {
      /*scroll-snap-align: start;*/
      width: 100%;
      height: 15vh;
      margin-bottom: 3vh;
    }
    a {
      color: black;
      text-decoration: none;
    }
    h3 {
      margin: 0;
      font-weight: 300;
      font-size: 14px;
    }
    span {
      position: absolute;
      top: 100%;
      bottom: -3rem;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid transparent;
      transition-duration: 0.5s;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      @media screen and (max-width: 920px) {
        top: 1vh;
        bottom: 1vh;
        left: 1vh;
        right: 1vh;
        border-color: black;
        width: initial;
        &:hover {
          span {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-color: black;
          }
        }
      }
    }

    &:hover {
      span {
        top: 0;
        bottom: 0;
        border-color: black;
      }
    }
  }
`;
const StyledImg = styled(Img)`
  display: block !important;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;
const BlurriedImg = styled.div`
  display: block !important;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  img {
    height: 100%;
    width: auto;
    transform: translateX(-25%);
    -webkit-filter: blur(15px); /* Safari 6.0 - 9.0 */
    filter: blur(15px);
  }
`;
function randomPos() {
  return `translateX(-${Math.floor(Math.random() * 10)}0%)`;
}

export default Realisations;
